import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../../@elegantstack/solid-ui-layout/src/Layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Contact from '@solid-ui-blocks/CallToAction/Block02'
import Footer from '../../blocks/Footer/Block01'
import {normalizeBlockContentNodes} from '@blocks-helpers'
import ContentColumn from "../../blocks/Content/ColumnNoAnim/Column";

const PinkertonPrivacyPolicy = props => {
    const {allBlockContent} = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
        <Layout {...props}>
            <Seo title='Política de privacidad de Pinkerton'/>
            {/* Blocks */}
            <Divider space='5'/>
            <ContentColumn content={content['content-one']}/>
            <ContentColumn content={content['content-two']}/>
            <Divider space='5'/>
        </Layout>
    )
}

export const query = graphql`
  query innerpageSitePinkertonPrivacyPolicyBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/pinkerton-privacy-policy", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default PinkertonPrivacyPolicy
